/* Sidebar.css */

.sidebar {
  background-color: #fff; /* Set the background color to white */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #000; /* Set the text color to black */
  width: 280px;
  padding: 10px;
  position: fixed; /* Fix the sidebar's position */
  height: 100%; /* Set the height to 100% of the viewport height */
  left: 0; /* Position it on the left side of the screen */
  top: 0; /* Position it at the top of the screen */
  overflow-y: auto; /* Add vertical scrollbar if content exceeds the viewport height */
  .content{
    height: 12%;
    display: flex;
    align-items: flex-start;
  }
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  padding: 15px 20px;
  color:gray;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 20px;
  height: 75%;
  width:100%;
  .i{
    color:gray;

  }
}

.sidebar li.active {
  background-color: #F3F5F7;
  color: #846EC3;
  .i{
    background-color: #846EC3;
  }
}

.main-content {
  width: 100%;

  .header {
    margin-left: 280px;
  }

  .content {
    margin-left: 280px;
    padding: 20px
  }
}

.row-card {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  .row-inner-card {
    display: flex;
    flex-direction: row;
    width: 23%;
    padding: 20px;
    box-shadow: 3px 3px rgba(238, 238, 238, 0.33);
    border-radius: 5px;
    align-items: center;
    justify-content: flex-start;

    .icon-card {
      display: flex;
      background-color: #F4F7FE;
      border-radius: 50%;
      padding: 20px;
    }

    .card-content {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
    }

    .icon-fa {
      color: #5F41B2;
    }
  }
}

.report-table {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .chart-table {
    display: flex;
    flex-direction: column;
    width: 74.5%;

    .table-content {
      padding: 25px;
      display: flex;
      flex-direction: column;
      width: 100%;
      box-shadow: 1px 1px 4px 4px rgba(239, 239, 239, 0.30);
      border-radius: 5px;
    }
  }

  .table-data {
    margin-top: 40px;

    .custom-tr {
      background-color: #F9FAFA;
    }

    th {
      border-color: white;
      font-size: 14px;
    }

    td {
      padding: 10px;
      font-size: 12px;
      border-color: white;
    }
  }

  .side-leaderboard-container {
    display: flex;
    flex-direction: column;
    width: 23%;

    .side-leaderboard {
      display: flex;
      width: 100%;
      flex-direction: column;
      box-shadow: 1px 1px 4px 4px rgba(238, 238, 238, 0.30);
      border-radius: 5px;
      padding: 20px;

      .top-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .inside-leaderboard {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: #F4F6F9;
        border-radius: 20px;
        padding: 10px 20px 10px 20px;
        align-items: center;
      }
    }
  }
}

.fa {
  color: #5F41B2;
}

span {
  color: #c4c4c4;
}